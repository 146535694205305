<template>
	<section
		class="section wow fadeIn"
		data-wow-offset="50"
	>
		<div class="content">
			<div class="vacancies-block">
				<div class="grid">
					<div class="box">
						<BaseSidebar
							:filters="filters"
							:cities="notNullSlug('cities').slice(0, filters.cities.localPage * 10)"
							:text-more-cities="textMoreNotNullSlug('cities')"
							:value-cities="filters.cities.value"
							:companies="notNullSlug('companies').slice(0, filters.companies.localPage * 10)"
							:text-more-companies="textMoreNotNullSlug('companies')"
							:value-companies="filters.companies.value"
							:activities="notNullSlug('activities').slice(0, filters.activities.localPage * 10)"
							:value-activities="filters.activities.value"
							:specs="notNullSlug('specs').slice(0, filters.specs.localPage * 10)"
							:value-specs="filters.specs.value"
							:types="notNullSlug('types').slice(0, filters.specs.localPage * 10)"
							:value-types="filters.types.value"
							:is-show="filters.isShow"
							:is-show-cities="filters.cities.isShow"
							:is-show-companies="filters.companies.isShow"
							:is-show-activities="filters.activities.isShow"
							:is-show-specs="filters.specs.isShow"
							:is-show-types="filters.types.isShow"
							@showMoreNotNullSlug="showMoreNotNullSlug"
							@changeParametersFilters="acceptFilter"
							@clearFilter="clearFilter"
							@showFilters="showFilters"
							@showFilterCities="showFilterCities"
							@showFilterCompanies="showFilterCompanies"
							@showFilterActivities="showFilterActivities"
							@showFilterSpecs="showFilterSpecs"
							@showFilterTypes="showFilterTypes"
						/>
					</div>
					<div class="box">
						<div
							v-if="!vacancies || isLoading"
							class="preloader sm"
							style="margin-top: 100px"
						/>
						<template v-else>
							<div class="vacancies-number">
								Найдено вакансий: <span>{{ filters.amount }}</span>
							</div>
							<div class="vacancy-card-group">
								<BaseCardVacancies
									v-for="(vacancy, index) in vacancies"
									:key="index"
									:is-favorite="isFavorite(vacancy.id)"
									:is-favorite-vacancy="isFavorite(vacancy.id)"
									:vacancy="vacancy"
									class="vacancy-card-item"
								/>
							</div>
							<PagePagination
								v-if="pagination.ttl > 1"
								:pagination="pagination"
								:pages="pages"
								:is-show-total="isShowTotal"
								:is-show-next="isShowNext"
								@changePage="selectPage"
							/>
						</template>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>

import {mapActions, mapGetters} from 'vuex';
import {debounce} from '@/assets/scripts/common';
import BaseCardVacancies from '@/components/BaseComponents/BaseCardVacancies';
import PagePagination from '@/components/PagePagination';
import BaseSidebar from '@/components/BaseComponents/BaseSidebar/BaseSidebar';

export default {
    components: {
        BaseCardVacancies,
        PagePagination,
        BaseSidebar,
    },
    data: () => ({
        isLoading: true,
        filters: {
            amount: undefined,
            isShow: false,
            search: '',
            cities: {
                search: '',
                count: undefined,
                localPage: 1,
                page: 1,
                value: [],
                list: [],
                isShow: false,
            },
            companies: {
                search: '',
                count: undefined,
                localPage: 1,
                page: 1,
                value: [],
                list: [],
                isShow: false,
            },
            activities: {
                count: undefined,
                localPage: 1,
                page: 1,
                value: [],
                list: [],
                isShow: false,
            },
            specs: {
                count: undefined,
                localPage: 1,
                page: 1,
                value: [],
                list: [],
                isShow: false,
            },
            types: {
                value: [],
                isShow: false,
            }
        },
        pagination: {
            init: 0,
            crnt: 1,
            ttl: undefined
        }
    }),
    computed: {
        ...mapGetters('org', {
            vacancies: 'getOrganizationVacancies',
            activities: 'getOrganizationActivities',
            specs: 'getOrganizationSpecialisation',
            types: 'getOrganizationTypeEmployment'
        }),
        ...mapGetters('user', {
            isLogged: 'isLoggedIn',
            favoriteVacancies: 'favoriteVacancies'
        }),
        ...mapGetters('cnst', {
            employments: 'getEmployments'
        }),
        favoriteVacanciesId() {
            return this.favoriteVacancies.map((el) => {
                return el.id
            })
        },
        pages() {
            let counter = 1;
            let temp = [];
            let max = Math.ceil(this.filters.amount / 30) < 3 ? Math.ceil(this.filters.amount / 30) : 3
            while (counter <= max) {
                temp.push(this.pagination.init + counter);
                counter++;
            }
            return temp;
        },
        isShowTotal() {
            if (this.filters.amount) return Math.ceil(this.filters.amount / 30) > 3
            return false
        },
        isShowNext() {
            return (this.pagination.init !== this.pagination.ttl - 4) && this.isShowTotal
        }
    },
    async created() {
        if(this.isLogged) {
            this.$store.dispatch('user/getFavoriteVacancies')
        }
        this.getFilterVacancies()
        let [cities, companies, activities, specs, types] = await Promise.all([
            this.getCities({
                id: this.$route.params.id,
                type_employment: this.filters.types.value
            }),
            this.getCompanies({
                id: this.$route.params.id,
                type_employment: this.filters.types.value
            }),
            this.getActivities({
                id: this.$route.params.id,
                type_employment: this.filters.types.value
            }),
            this.getSpecs({
                id: this.$route.params.id,
                type_employment: this.filters.types.value
            }),
            this.getTypes(this.$route.params.id)
        ])
        this.filters.cities.count = cities.length
        this.filters.cities.list = cities
        this.filters.companies.count = companies.length
        this.filters.companies.list = companies
        this.filters.activities.count = activities.length
        this.filters.activities.list = activities
        this.filters.specs.count = specs.length
        this.filters.specs.list = specs
        this.filters.types.count = types.length
        this.filters.types.list = types
    },
    mounted() {
        this.callDebounceWrapper =
            debounce(() => {
                let inputTime = new Date().getTime();
                setTimeout(() => {
                    let callTime = new Date().getTime();
                    if (callTime >= inputTime + 1000) {
                        this.getFilterVacancies()
                    }
                }, 1000)
            }, 1000)
    },
    methods: {
        ...mapActions('org', {
            getOrganization: 'getOrganizationVacancies',
            getCities: 'getOrganizationCities',
            getActivities: 'getOrganizationActivities',
            getCompanies: 'getOrganizationCompanies',
            getSpecs: 'getOrganizationSpecialisation',
            getTypes: 'getOrganizationTypeEmployment',
        }),
        ...mapActions('user', ['addFavoriteVacancy']),
        getFilterVacancies() {
            this.getOrganization({
                id: this.$route.params.id,
                vacancy_name: this.filters.search,
                cities_vacancies: this.filters.cities.value,
                companies_vacancies: this.filters.companies.value,
                field_activity_vacancies: this.filters.activities.value,
                specialisation_vacancies: this.filters.specs.value,
                type_employment: this.filters.types.value,
                page: this.pagination.crnt
            })
                .then(result => {
                    this.filters.amount = result.count
                    this.pagination.ttl = Math.ceil(result.count / 30)
                })
                .then(() => this.isLoading = false)
        },
        async clearFilter() {
            let [cities, companies, activities, specs, types] = await Promise.all([
                this.getCities({
                    id: this.$route.params.id,
                    type_employment: this.filters.types.value
                }),
                this.getCompanies({
                    id: this.$route.params.id,
                    type_employment: this.filters.types.value
                }),
                this.getActivities({
                    id: this.$route.params.id,
                    type_employment: this.filters.types.value
                }),
                this.getSpecs({
                    id: this.$route.params.id,
                    type_employment: this.filters.types.value
                }),
                this.getTypes(this.$route.params.id)
            ])
            this.filters = {
                amount: undefined,
                isShow: false,
                search: '',
                cities: {
                    search: '',
                    count: cities.length,
                    localPage: 1,
                    page: 1,
                    value: [],
                    list: cities,
                    isShow: false,
                },
                companies: {
                    search: '',
                    count: companies.length,
                    localPage: 1,
                    page: 1,
                    value: [],
                    list: companies,
                    isShow: false,
                },
                activities: {
                    count: activities.length,
                    localPage: 1,
                    page: 1,
                    value: [],
                    list: activities,
                    isShow: false,
                },
                specs: {
                    count: specs.length,
                    localPage: 1,
                    page: 1,
                    value: [],
                    list: specs,
                    isShow: false,
                },
                types: {
                    count: types.length,
                    localPage: 1,
                    page: 1,
                    value: [],
                    list: types,
                    isShow: false,
                }
            }
            this.getFilterVacancies()
        },
        acceptFilter(filters) {
            this.filters.cities.value = filters.localCities
            this.filters.companies.value = filters.localCompanies
            this.filters.activities.value = filters.localActivities
            this.filters.specs.value = filters.localSpecs
            this.filters.types.value = filters.localTypes
            this.pagination.crnt = 1
            this.getFilterVacancies()
        },
        showFilters(isShow) {
            this.filters.isShow = isShow
        },
        showFilterCities(isShow) {
            this.filters.cities.isShow = isShow
        },
        showFilterCompanies(isShow) {
            this.filters.companies.isShow = isShow
        },
        showFilterActivities(isShow) {
            this.filters.activities.isShow = isShow
        },
        showFilterSpecs(isShow) {
            this.filters.specs.isShow = isShow
        },
        showFilterTypes(isShow) {
            this.filters.types.isShow = isShow
        },
        selectPage(obj) {
            this.$scrollTo(document.querySelector('#organizationTabs'))
            this.pagination.crnt = obj.item;
            this.getFilterVacancies()

            if (this.pagination.ttl === obj.item) {
                if (this.pagination.ttl >= 4) this.pagination.init = this.pagination.ttl - 4
                else this.pagination.init = 0
            }
            else if (obj.index === 0 && obj.item > 1) this.pagination.init--;
            else if (obj.index === 30 && obj.item < this.pagination.ttl - 1) this.pagination.init++;
        },
        notNullSlug(slug) {
            if (this.filters[slug].list !== undefined && this.filters[slug].list.length) {
                return this.filters[slug].list.filter(item => !!item.count)
            }
            return []
        },
        textMoreNotNullSlug(slug) {
            if (this.filters[slug].localPage * 10 < this.filters[slug].list.length) {
                return `Показать еще ${this.filters[slug].list.length - this.filters[slug].localPage * 10 >= 10 ? 10 :
                    this.filters[slug].list.length - this.filters[slug].localPage * 10}`
            } else if (this.filters[slug].localPage * 10 < this.filters[slug].count)
                return `Показать еще ${this.filters[slug].count - this.filters[slug].localPage * 10 >= 10 ? 10 :
                    this.filters[slug].count - this.filters[slug].localPage * 10}`
            return ''
        },
        showMoreNotNullSlug(slug) {
            if (this.filters[slug].localPage * 10 < this.filters[slug].list.length) {
                this.filters[slug].localPage++
            } else if (this.filters[slug].count > this.filters[slug].list.length) {
                slug === 'cities' && this.getCities({
                    search: this.filters[slug].search ? this.filters[slug].search[0].toUpperCase() + this.filters[slug].search.slice(1) : '',
                    page: ++this.filters[slug].page
                })
                    .then(result => {
                        this.filters[slug].list = this.filters[slug].list.concat(result.results)
                        this.filters[slug].localPage++
                    })
            }
        },
        collapseBlockSlug(slug) {
            this.filters[slug].isShow = !this.filters[slug].isShow
            if (this.filters[slug].localPage * 10 >= this.filters[slug].count) this.filters[slug].isShowAll = true
            else this.filters[slug].isShowAll = false
        },
        isFavorite(id) {
            return this.favoriteVacanciesId.includes(id)
        },
        eventYandexRespond() {
            if(this.$metrika && (window.location.hostname === 'stage.lift.teachbasetest.ru' || window.location.hostname === 'lift-bf.ru')) {
                this.$metrika.reachGoal('click_response_vacancy', {
                    URL: document.location.href
                })
            }
            if (this.$store.getters['user/isLoggedIn']) {
                this.modalOpen('reqVacancy')
            } else {
                this.modalOpen('signIn')
            }
        },
    }
}
</script>

<style lang="less">
</style>
